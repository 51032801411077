import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'

export const FirebaseApp = firebase.initializeApp({
                              apiKey: process.env.VUE_APP_apiKey,
                              authDomain: process.env.VUE_APP_authDomain,
                              databaseURL: process.env.VUE_APP_databaseURL,
                              projectId: process.env.VUE_APP_projectId,
                              storageBucket: process.env.VUE_APP_storageBucket,
                              messagingSenderId: process.env.VUE_APP_measurementId,
                              appId: process.env.VUE_APP_appId,
                              measurementId: process.env.VUE_APP_measurementId
});

export const db = FirebaseApp.database();
export const auth = FirebaseApp.auth();
export const analytics = FirebaseApp.analytics();