<template>
  <div class="livestream flex-grow-1 nav-spcr text-left">
      <div v-if="isLoading">
        Loading
      </div>
      <div v-else class="row">
          <div class="col-12 col-lg-8 pl-lg-5 pt-lg-4 pr-lg-4">
              <video controls ref="livestream-player" class="embed-responsive embed-responsive-16by9"></video>
                  <div class="d-lg-none px-3 mt-2">
                    <router-link to="/download" @click.native="toggleMobileMenu" class="btn btn-primary mx-auto">DOWNLOAD SYPHER</router-link>
                  </div>
              <div class="row mx-0 no-gutters justify-content-between pl-3 pl-lg-0">
                  <div class="col-auto details mt-3">
                    <div>
                        <p class="mb-0 title">{{streamMetaData.title}}</p>
                        <p class="mb-0 watchCount">{{streamMetaData.watchCount}} Watching</p>
                    </div>
                  </div>
                  <div class="col-auto actions pt-3 pr-3 pr-lg-0">
                      <button class="btn btn-action" v-on:click="copyToClipboard"><img src="../assets/icons/share.svg" alt="Share this livestream"></button>
                  </div>
              </div>
             <hr/>
            <div class="description stream-description">
                <div class="row pl-3 pl-lg-0">
                    <div class="col-12">
                        Start time & {{streamMetaData.location}}
                    </div>
                    <div class="col-12 mt-2">
                        <p>{{streamMetaData.description}}</p>
                    </div>
                    <div class="col-12">
                        <p><router-link to="/download" @click.native="toggleMobileMenu">Download</router-link> the SYPHER app for best experience and to chat with others.</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 pt-lg-4">
              <div class="artist pl-3 pl-lg-0">
                <p class="font-weight-bold h5">Artist</p>
                <hr class="light">
                  <div class="mb-4 row no-gutters">
                    <div class="col-auto">
                      <img v-bind:src="streamMetaData.hostProfileURLStr || blankProfileImg" class="hostImage">
                    </div>
                    <div class="col-auto flex-grow-1">
                      <div class="pl-3"> 
                          <div class="hostUsername"> {{streamMetaData.hostUsername}} </div>
                          <div class="hostName"> {{streamMetaData.hostName}} </div>
                        </div>
                    </div>
                    <div class="col-auto pr-3">
                      <a class="btn button mt-2" target="_blank" v-bind:href="streamMetaData.donationURLStr">TIP</a>
                    </div>
                  </div>
              </div>
              
              <div class="live-chat pl-3 pl-lg-0 d-block">
                  <p class="font-weight-bold h5">Live Chat</p>
                  <hr class="light mb-0">
                  <div class="chats" ref="chat-area">
                  <div v-bind:key="message.id" v-for="message in chatMessages">
                      <!-- Bind Chat Cells Here --> 
                        <div class="pt-3">
                            <img v-bind:src="message.userProfileURLStr || blankProfileImg "  class="image">
                             <div class="ml-5"> 
                                <div class="username"> {{message.username}} </div>
                                <div class="message"> {{message.chat}} </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
import {
  db,
  auth,
  FirebaseApp
} from '@/Firebase';

export default {
  data: function () {
    return {
      streamMetaData: {
        description: "",
        donationURLStr: "",
        hostName: "",
        hostProfileURLStr: "",
        hostUUID: "",
        hostUserType: "",
        hostUsername: "",
        location: "",
        streamId: "",
        streamPlaybackId: "",
        title: "",
        ts: "",
        watchCount: "",
        shareURLStr: "",
        isLive: false
      },
      chatMessages: null,
      lastPlayId: null,
      hasLoaded: false
    }
  },
  computed: {
    blankProfileImg() {
      return require('../assets/icons/empty-profile-pic.svg')
    }
  },
  watch: {
    chatMessages: function () {
      // There is a race condition where this is called before the dom is updated
      // so call it after time so the chat scrolls to the most recent message
      setTimeout(this.scrollChat, 200);
    },
    streamMetaData: function () {

      if (this.streamMetaData.streamPlaybackId === "" || !this.streamMetaData.streamPlaybackId) {
        return
      } else {
        if (!this.hasLoaded) {
          db.ref(`livestreams/${this.$route.params.streamId}`).update({
            'watchCount': this.streamMetaData.watchCount + 1
          })
        }
        this.hasLoaded = true;
      }

      // Only configure the player if the playback id changes or gets set for the first time
      if (this.lastPlayId !== this.streamMetaData.streamPlaybackId) {
        var video = this.$refs['livestream-player']
        var videoSrc = `https://stream.mux.com/${this.streamMetaData.streamPlaybackId}.m3u8`;
        if (Hls.isSupported()) {
          var hls = new Hls();
          hls.loadSource(videoSrc);
          hls.attachMedia(video);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            video.play();
          });
        }
        this.lastPlayId = this.streamMetaData.streamPlaybackId
      }
    }
  },
  mounted() {
    auth.signInAnonymously()
    if (process.env.NODE_ENV === 'production') {
      let analytics = FirebaseApp.analytics()
      analytics.logEvent('watch_livestream', {
        item_id: this.$route.params.streamId
      });
    }
  },
  firebase() {
    return {
      streamMetaData: db.ref(`livestreams/${this.$route.params.streamId}`),
      chatMessages: db.ref(`livestream-chats/${this.$route.params.streamId}`)
    }
  },
  methods: {
    copyToClipboard: function () {
      // Create new element
      var element = document.createElement('textarea');
      element.value = this.streamMetaData.shareURLStr;
      element.setAttribute('readonly', '');
      element.style = {
        position: 'absolute',
        left: '-9999px'
      };
      document.body.appendChild(element);
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);
      document.execCommand("copy");
      this.$bvToast.show('share-link')

      this.$bvToast.toast("Share with friends & family!", {
        title: 'Link Copied',
        autoHideDelay: 2000,
        variant: "gray-dark",
        appendToast: false
      })
    },
    scrollChat: function () {
      this.$refs['chat-area'].scroll({
        top: this.$refs['chat-area'].scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

}
</script>
<style scoped lang="scss">
    
    hr {
        border-color: var(--gray);

        &.light {
             border-color: var(--gray-light);
        }
    }

    .btn-action {
        background-color: var(--gray);
        padding: 12px !important;

        img {
            width: 20px;
            height: 20px;
        }
    }

    .stream-description{
        color: darkgray;
    }

    .watchCount{
        color: darkgray;
    }

    .title{
        font-weight: bold;
        font-size: 25px;
    }

    .hostImage{
        border-radius: 50%;
        width: 50px;
        height: 50px;
        float: left;
    }

    .hostUsername{
        font-weight:700;
        font-size: 17px;
    }

    .hostName{
        font-weight:400;
        font-size: 15px;
        color: darkgray;
    }

    .button {
        background-color: #0C0C0C;
        border: 2px solid var(--gray-dark) !important;
        position: relative;
        color: #0779e4;
        padding: 5px;
        width: 120px;
        text-align: center;
        border-radius: 25px;
        text-decoration: none !important;
        float: right;
        font-size: 16px;
        font-weight: 600;

        &:hover {
          background-color: rgba($color: #0779e4, $alpha: 0.3);
        }
    }

    .image{
        border-radius: 50%;
        width: 40px;
        height: 40px;
        float: left;
        object-fit: cover;
    }
    .message-margin{
        margin-left: 50px;
    }

    .username{
        font-weight:700;
        font-size: 15px;
    }

    .message{
        font-weight:400;
        font-size: 15px;
    }


    .chats {
      height: 500px;
      overflow-y: auto;
    }
</style>